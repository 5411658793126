.form-container {
  justify-content: center;
  align-items: center;
}

input[type='email'], input[type='password'] {
  background-color: var(--bg-container-color);
  border: 1px solid var(--bg-light);
  padding: 10px 12px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

input[type='email']:focus,
input[type='password']:focus {
  outline: none;
}

input[type='email']:focus + label,
input[type='password']:focus + label {
  font-size: 16px;
  transform: translateY(-120%) translateX(-10%);
  z-index: 2;
}

input:-webkit-autofill{
  -webkit-box-shadow: 0 0 0px 1000px var(--bg-container-color) inset; 
}

.form-group-text1{
  font-weight: 700;
}

.button-text{
  padding: 5px;
  background-color: transparent;
  color:var(--secondary-text-colour);
  font-weight:  700;
}

button[type="submit"] {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  transition: background-color 0.3s ease;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 80%;
} 

.error-message {
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-size: 0.9em;
  font-weight: 700;
  margin-top: 20px;
  padding: 12px 0;
  text-align: center;
  text-transform: uppercase;
}

/* Login */
.login .auth-recover-button {
  background-color: transparent;
  color: var(--secondary-text-colour);
  position: absolute;
  right: 0;
  font-weight: 700;
}

.login .auth-recover-button:hover{
  background-color: transparent;
}

.disclaimer{
  font-size: 70%;
  margin:0%;
  padding-top: 20%;
}

.disclaimer p{
  margin-bottom: 0%;
}

.disclaimer button{
  font-size:80%;
  font-weight: 700;
  margin:0%;
  padding: 0%;
  background-color: transparent;
}
/*Authentication section*/

.authentication-section {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 80vh;
}

.auth-box.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.authentication-form-container img {
  display: block;
  margin: 0 auto; /* center the image inside the container */
}

.authentication-form-container h1 {
  margin-top: 10%;
}

.div-button{
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

.div-button button{
  border: 1px solid var(--primary-color);
  font-weight: 700;
  margin: 2%;
  padding: 5%;
}

.auth-button-1  {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}


.auth-button-1:hover {
  background-color: var(--secondary-color);
  color: var(--secondary-text-color);
}

.auth-button-2  {
  background-color: var(--secondary-color);
  color: var(--secondary-text-color);
}

.auth-button-2:hover {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}


@media (min-width:1199px) {
  button[type="submit"] {
    font-size: 24px;
  } 

  .login .auth-recover-button {
    transform: translateY(-60%);
    font-size: 12px;
  }
}

@media (max-width:767px)  and (max-height: 1023px){
  button[type="submit"] {
    font-size: 1em;
  }

  .login .auth-recover-button {
    transform: translateY(-40%);
    font-size: 0.7em;
  }
}