.add-block {
  display: flex;
  flex-direction: row; /* Change the flex direction to row */
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.add-block.container{
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

.add-block button.disable-button{
  background-color: #ccc;
}

.add-block button.disable-button:hover {
  background-color: #ccc;
  color: var(--primary-text-color);
}

.add-block button  {
  margin:1% 1%;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-weight: 700;
}

.add-item{
  display:flex;
  justify-content: center;
}

.add-item button{
  margin:1% 1%;
  height:4em;
  width: 100%;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-size: large;
  font-weight: 700;
}

.add-block button:hover {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

.add-block-box input {
  display:flex;
  margin: 1% 1%;
  border: none;
  border-radius: 5px;
  background-color: var(--bg-container-color);
}

.add-block-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  border: 2px solid #ccc;
  width:100%;
  border-radius: 5px;
}

.add-block-box input[type="text"] {
  border: none;
  outline: none;
  transform: translateY(0) translateX(0);
  z-index: 2;
}

.add-block-box i {
  margin-left: 10px;
  color: #333;
}

.current-items.title{
  padding:1%;
}

.current-items.container button{
  width: 100%;
  font-size:xx-large;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-weight: bold;
}

.display-item.container{
  margin-bottom: 1em;
}

.display-item div.title-area{
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Places elements on opposite sides */
}

.display-item button.remove-item{
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  color: red;
  cursor: pointer;
}

.number-row {
  display: flex;
  align-items: center;
}

.number-row label {
  margin-right: 5px;
}

.number-row.quantity {
  padding: 5px;
}

.display-item.current h3{
  color: var(--secondary-text-color);
}

.text-row label {
  background-color: var(--bg-container-color);
  text-align: left;
  align-self: flex-start;
  font-size: 110%;
  font-weight: 700;
  color: var(--secondary-text-colour);
  transition: all 0.2s ease;
  pointer-events: none;
}

.text-row p{
  margin-top:0%;
  background-color: var(--bg-container-color);
}

.popup{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
}

.popup div {
  margin: 2%; 
}

.popup .links {
  display: flex;
  flex-direction: column;
  align-items: center; /* Vertically center items */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other content */
}

.block-data.container{
  padding: 2%;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  width: 100%;
  box-sizing: border-box;
}

.content, .h-content {
  display: flex;
  position: relative;
  position: inherit;
}

.section {
  background-color: #0F0F17;
  color: #fff;
  padding: 20px;
}

.section p {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

section.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-data {
  text-align: center;
}

.block-data h5 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.block-data p {
  margin: 5px 0;
}

hr {
  border: 0;
  height: 1px;
  background-color: #444;
  margin: 10px 0;
}

.sub-qr-edit.display{
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items in the center horizontally */
  text-align: center;
}

.sub-qr-edit .button-back, .sub-qr-edit .button-export{
  width:100%;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  font-size: large;
  font-weight: 700;
}

.sub-qr-edit.display .container{
  width:50%
}

@media (min-width:1199px) {
  .content{
    flex-direction: row;
    justify-content: space-evenly;
  }

  .edit{
    width: 75%;
    padding: 1%;
  }

  .sub-qr-edit{
    width: 25%;
    padding: 1%;
  }
  
  .add-block.container{
    padding: 1em;
    height:6vh;
  }
  
  .add-block button  {
    height:4em;
    width: 10em;
    font-size: large;
  }

  .add-block-box {
    display: grid;
    place-items: center;
}
  
  .add-block-box input {
    width: 100%;
    font-size: 20px;
    box-sizing: border-box;
}
    
  .add-block-box input[type="text"] {
    font-size: 16px;
  }
    
  .add-block-box i {
    font-size: 16px;
  }
  
  .current-items.title{
    font-size:2em
  }
    
  .display-item.container{
    padding: 2em;
  }
    
  .text-row{
    margin-top: 40px;
  }
}

@media (max-width:767px)  and (max-height: 1023px) {
  .content,.h-content{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .edit,.sub-qr-edit{
    width: 95%;
  }

  .add-block.container{
    padding: 0.5em;
  }
  
  .add-block button  {
    height:3.5em;
    width: 8em;
    font-size: smaller;
  }
    
  .add-block-box input {
    width: 90%;
    font-size: smaller;
  }

  .add-block-box input[type="text"] {
    font-size: small;
  }
      
  .add-block-box i {
    font-size: small;
  }
    
  .current-items.title{
    padding-top:3%;
    font-size:large
  }
    
  .display-item.container{
    padding: 0.5em;
  }
      
  .text-row{
    margin-top: 5vh;
  }
}