.qr-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 5%;
    margin-top: 2vh;
}

.qr-section > * {
    margin: 1em;
}

.qr-section button{
    background: padding-box;
    background-color: var(--bg-container-color);
    box-shadow: 0px 5px 15px #0000511A;
    border-radius: 10px;
    height: 17.5em;
    opacity: 1;
}


.qr-section button.selected{
    background-color: #0000511A;
}

.qr-section h5{
    color: var(--primary-color);
}

@media (min-width:1200px){
    .qr-section {
        justify-content: center;
        margin-top: 1%;
    }

    .qr-section button{
        width: 15em;
    }
    
}

@media (max-width:767px)  and (max-height: 1023px){

    .qr-section {
        justify-content: space-evenly;
        width: 100%;
    }

    .qr-section button{
        height: 30vh;
        width: 10em;
    }

}