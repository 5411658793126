
.img-container {
    text-align: center; /* Center the image horizontally */
    overflow: hidden;
  }
  
.img-container img {
display: block; /* Make the image a block element */
margin: 0 auto; /* Center the image horizontally */
max-height: 75vh;
}