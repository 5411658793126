:root {
  --bg-color: #eff2f6;
  --bg-light: #9fa1a4;
  --bg-dark: #373738; 
  --bg-container-color: #ffff;
  --bg-img : url("images/bg-circle.svg");
  --text-color: #202020;
  --primary-color: #000051;
  --secondary-color: #e4e4ed;
  --primary-text-color: #e6e6e6;
  --secondary-text-colour: #000051;
}
@media (min-width:1200px) {
  body {
    background-color: var(--bg-color);
    background-image: var(--bg-img);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  /* Style for h2 */
  h2 {
    font-size: 2rem;
  }
  
  /* Style for h3 */
  h3 {
    font-size: 1.5rem;
  }
  
  /* Style for h4 */
  h4 {
    font-size: 1.2rem;
  }
  
  /* Style for h5 */
  h5 {
    font-size: 1rem;
  }
  
  /* Style for h6 */
  h6 {
    font-size: 0.8rem;
  }
  
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  button,
  input,
  textarea {
    background-color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    color: var(--secondary-text-color);
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    margin: 0;
    outline: none;
    padding: 10px;
  }
  
  button:active,
  input:active,
  textarea:active {
    background-color: var(--secondary-color);
    color: var(--text-color);
  }
  
  img {
    display: block;
  }
  
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  
  .form-group label {
    background-color: var(--bg-container-color);
    text-align: left;
    align-self: flex-start;
    font-size: 100%;
    font-weight: 700;
    margin-left: 10px;
    color: var(--secondary-text-colour);
    position: absolute;
    transition: all 0.2s ease;
    pointer-events: none;
    top: 10%;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .form-group {
    position: relative;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }
  
  .form-group input,textarea {
    background-color: var(--bg-container-color);
    border: 1px solid var(--bg-light);
    padding: 10px 12px;
    font-size: 0.9em;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
  }
  
  .form-group textarea {
    resize: vertical; /* Allow vertical resizing */
  }
  
  .form-group input,textarea:focus {
    outline: none;
  }
  
  .form-group input:focus + label{
    font-size: 16px;
    transform: translateY(-120%) translateX(-10%);
    z-index: 2;
  }
  
  input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px var(--bg-container-color) inset; 
  }
  
  textarea:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px var(--bg-container-color) inset; 
  }
  
  .container{
    background-color: var(--bg-container-color);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 50px;
    margin: 0 auto;
  }  
}

@media (max-width:767px)  and (max-height: 1023px){
  body {
    background-color: var(--bg-color);
    background-image: var(--bg-img);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  /* Style for h2 */
  h2 {
    font-size: 2rem;
  }
  
  /* Style for h3 */
  h3 {
    font-size: 1.5rem;
  }
  
  /* Style for h4 */
  h4 {
    font-size: 1.2rem;
  }
  
  /* Style for h5 */
  h5 {
    font-size: 1rem;
  }
  
  /* Style for h6 */
  h6 {
    font-size: 0.8rem;
  }
  
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  button,
  input,
  textarea {
    background-color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    color: var(--secondary-text-color);
    font-family: 'Raleway', sans-serif;
    font-size: 0.9em;
    margin: 0;
    outline: none;
    padding: 7px;
  }
  
  button:active,
  input:active,
  textarea:active {
    background-color: var(--secondary-color);
    color: var(--text-color);
  }
  
  img {
    display: block;
  }
  
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  
  .form-group label {
    background-color: var(--bg-container-color);
    text-align: left;
    align-self: flex-start;
    font-size: 100%;
    font-weight: 700;
    margin-left: 10px;
    color: var(--secondary-text-colour);
    position: absolute;
    transition: all 0.2s ease;
    pointer-events: none;
    top: 10%;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .form-group {
    position: relative;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
  }
  
  .form-group input,textarea {
    background-color: var(--bg-container-color);
    border: 1px solid var(--bg-light);
    padding: 10px 12px;
    font-size: 0.9em;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
  }
  
  .form-group textarea {
    resize: vertical; /* Allow vertical resizing */
  }
  
  .form-group input,textarea:focus {
    outline: none;
  }
  
  .form-group input:focus + label{
    font-size: 0.9em;
    transform: translateY(-120%) translateX(-10%);
    z-index: 2;
  }
  
  input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px var(--bg-container-color) inset; 
  }
  
  textarea:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px var(--bg-container-color) inset; 
  }
  
  .container{
    background-color: var(--bg-container-color);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 50px;
    margin: 0 auto;
  }  
}