.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-content {
    display: flex;
    width: 100%;
}

.top-content .top-content-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-container-color);
    font-weight: 700;
    padding:3%;
}

.top-content .top-content-details button {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    font-weight: 700;
}

.top-content .top-content-details button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
}



.add-box input {
    padding: auto;
    border: none;
    border-radius: 5px;
    background-color: var(--bg-container-color);
}

.add-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 2px solid #ccc;
    border-radius: 5px;
    width:100%;
}

.add-box input[type="text"] {
    border: none;
    outline: none;
}

.add-box i {
    font-size: 0.9em;
    margin-left: 10px;
    color: #333;
}



@media (min-width:1200px){
    .top-content {
        width:100%;
        height: 10vh;
        margin-top: 1%;
    }
    
    .top-content .top-content-details {
      flex-direction: row;
      width: 40%;
      margin: 0 2.5%;
      font-size: x-large;
    }
    
    .top-content .top-content-details img {
      margin-right: 1% 5%;
    }
    
    .top-content .top-content-details a {
      margin-right: 20%;
    }
    
    .top-content .top-content-details button  {
      margin:5% 5%;
      width: 20%;
      background-color: var(--primary-color);
      color: var(--primary-text-color);
      font-size: large;
      font-weight: 700;
    }
    
    .add-box input {
      margin: 1%;
      width: 92.5%;
      font-size: 32px;
    }
    
}

@media (max-width:767px)  and (max-height: 1023px){
    .top-content {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 20vh;
        margin-top: 4vh;
        padding: 3%;
    }

    .top-content .top-content-details {
        flex-grow: 1;
        margin: 1%;
        height: 5vh;
        font-size: small;
        width:80%;
        padding:3%;
    }

    .top-content .top-content-details button{
        width: 10em;
    }

    .top-content .top-content-details button:hover {
        background-color: var(--secondary-color);
        color: var(--primary-text-color);
    }

    .qr-section {
        justify-content: space-evenly;
        width: 100%;
    }
    .add-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 2px solid #ccc;
        border-radius: 5px;
        margin: 5%;
    }

    .add-box input {
        font-size: 0.9em;
        width:95%
    }

    .add-box input[type="text"] {
        border: none;
        outline: none;
        font-size: 0.9em;
    }

    .qr-section button{
        height: 30vh;
        width: 10em;
    }

}

