header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-container-color);
  width: 100%;
  box-shadow: 0px 5px 10px #EBEBFF;
  z-index: 1;
  height: 12.5vh;
}

main {
  position:relative;
  height: 100%;
  z-index: 0;
  margin-top: 12.5vh;
}

.search-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  border: 2px solid #ccc;
  border-radius: 5px;
}

.search-box input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
  height: 100%;
}

.search-box i {
  color: #333;
}

input[type="search"] {
  background-color: transparent;
  /* add other styles as needed */
}

input[type="search"]::placeholder {
  font-style: italic;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;  
  background-color: var(--bg-container-color);
}

.navbar ul {
  list-style-type: none;
  text-align: center;
  width:100%;
  font-size: larger;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.navbar ul ul ul {
  top: 0;
  left: 100%;
}

.navbar ul ul li a {
  background-color: var(--bg-container-color);
}

.navbar ul ul li a:hover {
  background-color: var(--bg-container-color);
}

.navbar {
  margin-right: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;  
  background-color: var(--bg-container-color);
  padding: 10px;
}

@media (min-width:1199px) {
  header {
    height: 12.5vh;
  }

  main {
    margin-top: 12.5vh;
  }

  .search-box {
    width: 300px;
  }

  .search-box input[type="text"] {
    font-size: 16px;
  }

  .search-box i {
    font-size: 16px;
    margin-left: 10px;
  }

  input[type="search"] {
    font-size: 16px;
    width: 200px;
  }

  .logo {
    margin-left: 5%;
  }

  .logo img {
    width: 70%;
  }

  .navbar ul li {
    position: relative;
  }

  .navbar ul li a {
    display: block;
    padding: 10px 20px;
    font-size:medium;
    text-decoration: none;
    color: #333;
  }

  .navbar ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--bg-container-color);
    padding: 0;
  }
}
  
@media (max-width:767px)  and (max-height: 1023px) {    
  .search-box {
    width: 100px;
  }
  
  .search-box input[type="text"] {
    font-size: 16px;
  }
  
  .search-box i {
    font-size: 16px;
    margin-left: 10px;
  }
  
  input[type="search"] {
    font-size: 16px;
    width: 200px;
  }
  
  .logo {
    margin-left: 1%;
  }
  
  .logo img {
    width: 50%;
  }
  
  .navbar ul {
    list-style-type: none;
    text-align: center;
    width:100%;
    font-size: larger;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
  
  .navbar ul li a {
    display: block;
    padding: 10px 20px;
    font-size:medium;
    text-decoration: none;
    color: #333;
  }
  
  .navbar ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--bg-container-color);
    padding: 0;
  }
}